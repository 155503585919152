import lazyLoading from '../lazyLoading';

export default {
  name: 'Laboratuar sonuç',
  wdm16_id: '6260',
  path: '/patient/laboratory',
  component: lazyLoading('patient/laboratory'),
  icon: 'icon-pencil',
  required: ['patient'],
  permissions: ['patient_laboratory-view'],
  meta: {
    required: ['patient'],
    icon: 'fa-flask',
    expanded: false
  }
};
