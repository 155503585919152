import lazyLoading from '../lazyLoading';

export default {
  // name: 'education',
  name: 'education',
  caption: 'Eğitim',
  wdm16_id: '6257',
  icon: 'icon-pencil',
  path: '/patient/education',
  component: lazyLoading('patient/education'),
  required: ['patient'],
  permissions: ['education_patient-educated'],
  meta: {
    required: ['patient'],
    icon: 'fa-pencil',
    expanded: false
  }
};
