import lazyLoading from '../lazyLoading';

export default {
  name: 'patient_order',
  caption: 'İstem',
  wdm16_id: '6259',
  icon: 'icon-drop',
  path: '/patient/patient_order',
  component: lazyLoading('patient/patient_order'),
  required: ['patient'],
  permissions: ['wisdom', 'general_open-test-model'],
  meta: {
    required: ['patient'],
    icon: 'fa-circle',
    expanded: false
  }
};
