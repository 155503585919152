import Vue from 'vue';
import { API_BASE_URL } from '@/config';
export default {
  last_wisdomera_version () {
    return Vue.http.get(API_BASE_URL + 'version/last_wisdomera_version');
  },
  get_all_versions () {
    return Vue.http.get(API_BASE_URL + 'version/get_all_versions');
  }
};
