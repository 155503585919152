import lazyLoading from '../lazyLoading';

export default {
  name: 'Kemoterapi',
  caption: 'Kemoterapi',
  wdm16_id: '4550',
  //  path: '/patient/chemotherapy',
  //  component: lazyLoading('patient/treatment/index'),
  icon: 'icon-star',
  required: ['patient'],
  permissions: ['oncology_treatment_treatment-list-view', 'oncology_treatment_treatment-add-edit-view', 'patient_bmi-view', 'patient_laboratory-view'],
  meta: {
    required: ['patient'],
    icon: 'fa-stethoscope',
    expanded: false
  },
  children: [{
    name: 'patient_chemotherapy',
    caption: 'Tedavi listesi',
    wdm16_id: '6251',
    icon: 'icon-star',
    path: '/patient/chemotherapy',
    component: lazyLoading('patient/treatment/index'),
    required: ['patient'],
    sub_permissions: ['oncology_treatment_treatment-list-view'],
    meta: {
      required: ['patient'],
      icon: 'fa-hand-o-right',
      expanded: false
    }
  }, {
    name: 'new_treatment',
    caption: 'Yeni tedavi',
    wdm16_id: '6252',
    icon: 'icon-star',
    path: '/patient/treatment/add_edit',
    component: lazyLoading('patient/treatment/add_edit'),
    required: ['patient'],
    sub_permissions: ['oncology_treatment_treatment-add-edit-view'],
    meta: {
      required: ['patient'],
      icon: 'fa-hand-o-right',
      expanded: false
    },
    props: true
  }]
};
