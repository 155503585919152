import lazyLoading from '../lazyLoading';

export default {
  // name: 'report',
  name: 'Döküman yükleme',
  caption: 'Döküman Yükleme',
  wdm16_id: '6255',
  path: '/patient/document_upload',
  icon: 'icon-pencil',
  component: lazyLoading('patient/document_upload'),
  required: ['patient'],
  permissions: ['wisdom'],
  meta: {
    required: ['patient'],
    icon: 'fa-pencil',
    expanded: false
  }
};
