export function show_date(value) {
  if (value) {
    let check = value.indexOf('days');
    let str = value;
    if (check !== -1) {
      str = value.slice(0, check - 1);
    }
    return new Date(str).toLocaleDateString();
  }
}

export function show_time(value) {
  if (value) {
    return new Date(value).toLocaleString('en-GB', { hour12: false });
  }
}

export function show_drugs(value) {
  if (value) {
    let res = value.indexOf('undefined');
    if (res !== -1) {
      return value.slice(0, res);
    }
    let count = false;
    for (let i = value.length - 1; i >= 0; i--) {
      if (!isNaN(value[i]) && !isNaN(parseFloat(value[i]))) {
        count = i;
      } else {
        break;
      }
    }
    return count ? value.slice(count) : value;
  }
}

export function add_day(value, days) {
  return new Date(value).addDays(days).toLocaleDateString();
}

export function show_unit(filter_unit) {
  return filter_unit.replace('_', '/').replace('2', '²');
}

export function show_unit_calculate(filter_unit) {
  if (filter_unit === 'mg_m2' || filter_unit === 'mcg_m2' || filter_unit === 'mu_m2' || filter_unit === 'unit_m2') {
    return filter_unit.replace('_', ' ').replace('m2', ' ');
  } else if (filter_unit === 'auc') {
    return 'mg';
  } else if (filter_unit === 'mg_kg' || filter_unit === 'mcg_kg' || filter_unit === 'mu_kg') {
    return filter_unit.replace('_', ' ').replace('kg', ' ');
  } else {
    return filter_unit;
  }
}

export function show_dosage_filter(dosage) {
  let dosage_form = [
    { 'caption': 'Ağız içi', 'value': '1' },
    { 'caption': 'Ağızdan (oral)', 'value': '2' },
    { 'caption': 'Burun içi (intranazal)', 'value': '3' },
    { 'caption': 'Cilt üzerine (epidermal)', 'value': '4' },
    { 'caption': 'Dış kulak yolu', 'value': '5' },
    { 'caption': 'Dil altı (sublingual)', 'value': '6' },
    { 'caption': 'Göz üzerine', 'value': '7' },
    { 'caption': 'İnhalasyon', 'value': '8' },
    { 'caption': 'İntra dermal', 'value': '9' },
    { 'caption': 'İntra musküler', 'value': '10' },
    { 'caption': 'İntra vajinal', 'value': '11' },
    { 'caption': 'intra venöz', 'value': '12' },
    { 'caption': 'intravenöz (Pompa ile)', 'value': '13' },
    { 'caption': 'İntraartiküler', 'value': '14' },
    { 'caption': 'İntrakaviter', 'value': '15' },
    { 'caption': 'İntraligamenter', 'value': '16' },
    { 'caption': 'İntraperitoneal', 'value': '17' },
    { 'caption': 'İntratekal', 'value': '18' },
    { 'caption': 'İntravezikal', 'value': '19' },
    { 'caption': 'İntravitreal', 'value': '20' },
    { 'caption': 'İntrakardiak', 'value': '21' },
    { 'caption': 'Kolon', 'value': '22' },
    { 'caption': 'Perinöral', 'value': '23' },
    { 'caption': 'Rektal', 'value': '24' },
    { 'caption': 'Solunum yolu', 'value': '25' },
    { 'caption': 'Subkutan', 'value': '26' },
    { 'caption': 'Transdermal', 'value': '27' }
  ];
  for (let i = 0; i < dosage_form.length; i++) {
    if (parseInt(dosage_form[i].value) === parseInt(dosage)) {
      return dosage_form[i].label;
    }
  }
}

export function caption_to_text(options) {
  let new_options = [];
  for (let i in options) {
    if (options[i].label) {
      new_options.push({ 'text': options[i].label, 'value': options[i] });
    }
  }
  return new_options;
};

export function select_func(val, data, x, options) {
  if (val) {
    for (let i in options) {
      if (options[i].label === val) {
        data[x] = options[i];
        return;
      }
    }
  }
  data[x] = '';
};
