import lazyLoading from '../lazyLoading';

export default {
  name: 'patient_follow',
  caption: 'Hasta takip',
  icon: 'icon-drop',
  wdm16_id: '6253',
  path: '/patient/patient_follow',
  component: lazyLoading('patient/patient_follow'),
  required: ['patient'],
  permissions: ['patient_view-follow-page'],
  meta: {
    required: ['patient'],
    icon: 'fa-circle',
    expanded: false
  }
};
