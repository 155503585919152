import lazyLoading from '../lazyLoading';

export default {
  name: 'patient_pharmacy',
  caption: 'Eczane',
  icon: 'icon-drop',
  wdm16_id: '6262',
  path: '/patient/patient_pharmacy',
  component: lazyLoading('patient/patient_pharmacy'),
  required: ['patient'],
  permissions: ['patient_view-follow-page'],
  meta: {
    required: ['patient'],
    icon: 'fa-circle',
    expanded: false
  }
};
