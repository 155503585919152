import * as types from './mutation-types';

export const toggleSidebar = ({ commit }, opened) => commit(types.TOGGLE_SIDEBAR, opened);

export const toggleDevice = ({ commit }, device) => commit(types.TOGGLE_DEVICE, device);

export const expandMenu = ({ commit }, menuItem) => {
  if (menuItem) {
    menuItem.expanded = menuItem.expanded || false;
    commit(types.EXPAND_MENU, menuItem);
  }
};

export const switchEffect = ({ commit }, effectItem) => {
  if (effectItem) {
    commit(types.SWITCH_EFFECT, effectItem);
  }
};

export const toggleLang = ({ commit }, lang) => commit(types.lang, lang);

export const push_queue = ({ commit, state }, data) => {
  // state.app.rule_queue.push(data);
  commit(types.rule_queue, data);
};
