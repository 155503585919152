import lazyLoading from '../lazyLoading';

export default {
  // name: 'patient_details',
  name: 'patient_details',
  caption: 'Kimlik bilgileri',
  wdm16_id: '6256',
  icon: 'icon-pencil',
  path: '/patient/details',
  component: lazyLoading('patient/index'),
  required: ['patient'],
  permissions: ['general_search-patient'],
  meta: {
    required: ['patient'],
    icon: 'fa-bars',
    expanded: false
  }
};
