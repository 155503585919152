import lazyLoading from '../lazyLoading';

export default {
  name: 'patient_drug',
  caption: 'Kemoterapi Listesi',
  wdm16_id: '6254',
  icon: 'icon-list',
  path: '/whsmodules/chemotherapy/patients-chemo-list',
  component: lazyLoading('whsmodules/chemotherapy/patients-chemo-list'),
  required: [''],
  permissions: ['oncology_patient-chemo-list_view'],
  meta: {
    required: [''],
    icon: 'fa-circle',
    expanded: false
  }
};
