const pkg = state => state.pkg;
const app = state => state.app;
const device = state => state.app.device;
const sidebar = state => state.app.sidebar;
const effect = state => state.app.effect;
const menuitems = state => state.menu.items;
const componententry = state => {
  return state.menu.items.filter(c => c.meta && c.meta.value === 'Components')[0];
};
const patient_selected = state => state.app.patient;
const websocket = state => state.app.websocket;
const lang = state => state.app.lang;
const rule_queue = state => state.app.rule_queue;
const help = state => state.app.help;
const loading = state => state.app.loading;
const test = state => state.app.test;
const modal = state => state.app.modal;
const account_list = state => state.app.account_list;
const user = state => state.app.user;
const wdm = state => state.app.wdm;
const department = state => state.app.department;
const version = state => state.app.version;
const messenger_data = state => state.app.messenger_data;
const screen_size = state => state.app.screen_size;

export {
  pkg,
  app,
  device,
  sidebar,
  effect,
  menuitems,
  componententry,
  patient_selected,
  lang,
  rule_queue,
  websocket,
  help,
  test,
  modal,
  loading,
  account_list,
  user,
  wdm,
  department,
  version,
  messenger_data,
  screen_size
};
