import lazyLoading from '../lazyLoading';

export default {
  name: 'patient_drug',
  caption: 'Hasta ilaçları',
  wdm16_id: '6261',
  icon: 'icon-drop',
  path: '/patient/drug',
  component: lazyLoading('patient/drug'),
  required: ['patient'],
  permissions: ['wisdom'],
  meta: {
    required: ['patient'],
    icon: 'fa-circle',
    expanded: false
  }
};
