export const TOGGLE_DEVICE = 'TOGGLE_DEVICE';
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const sidebar_control_on_user = 'sidebar_control_on_user';
export const sidebar_control = 'sidebar_control';
export const EXPAND_MENU = 'EXPAND_MENU';
export const SWITCH_EFFECT = 'SWITCH_EFFECT';
export const selected_patient = 'selected_patient';
export const ws_patient_id = 'ws_patient_id';
export const ws_chemo_list = 'ws_chemo_list';
export const ws_status = 'ws_status';
export const ws_socket_connection = 'ws_socket_connection';
export const websocket_id = 'websocket_id';
export const update_last_bmi = 'update_last_bmi';
export const last_lab_update = 'last_lab_update';
export const patient_treatment_list = 'patient_treatment_list';
export const patient_diagnosis_list = 'patient_diagnosis_list';
export const lang = 'lang';
export const rule_queue = 'rule_queue';
export const help_status = 'help_status';
export const help_no = 'help_no';
export const modal = 'modal';
export const modal_show = 'modal_show';
export const PatientWebserviceProtocolList = 'PatientWebserviceProtocolList';
export const PatientWebserviceProtocolListAll = 'PatientWebserviceProtocolListAll';
export const TestMode = 'TestMode';
export const Loading = 'Loading';
export const account_list = 'account_list';
export const user = 'user';
export const wdm = 'wdm';
export const department = 'department';
export const version = 'version';
export const patient_last_laboratory = 'patient_last_laboratory';
export const messenger_data = 'messenger_data';
export const screen_size = 'screen_size';
