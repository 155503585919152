import Vue from 'vue';
import { API_BASE_URL } from '@/config';
export default {
  get_server_ip () {
    return Vue.http.get(API_BASE_URL + 'global/get_server_ip');
  },
  get_one_cb_document (data) {
    return Vue.http.post(API_BASE_URL + 'global/get_one_cb_document', data);
  },
  push_one_cb_document (data) {
    return Vue.http.post(API_BASE_URL + 'global/push_one_cb_document', data);
  },
  get_multiple_cb_documents (data) {
    return Vue.http.post(API_BASE_URL + 'global/get_multiple_cb_documents', data);
  },
  get_multiple_cb_documents_by_defined_bucket (data) {
    return Vue.http.post(API_BASE_URL + 'global/get_multiple_cb_documents_by_defined_bucket', data);
  },
  get_cb_documents_with_key_list (data) {
    return Vue.http.post(API_BASE_URL + 'global/get_cb_documents_with_key_list', data);
  },
  get_user_holter (data) {
    return Vue.http.post(API_BASE_URL + 'global/get_user_holter', data);
  },
  database_query (data) {
    return Vue.http.post(API_BASE_URL + 'global/database_query', data);
  }
};
