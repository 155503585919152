import * as types from '../../mutation-types';
import patient_details from './patient/details';
import patient_follow from './patient/patient_follow';
import patient_treatment from './patient/treatment';
import patient_patient_drug from './patient/patient_drug';
import patient_education from './patient/education';
// import patient_report from './patient/report';
import patient_laboratory from './patient/laboratory';
import patient_pharmacy from './patient/patient_pharmacy';
import patient_order from './patient/patient_order';
import patient_document_upload from './patient/document_upload';
import patient_chemo_list from './patient/patient_chemo_list';
// show: meta.value -> name
// name: component name
// meta.value: display label

const state = {
  items: [
    patient_details,
    patient_follow,
    patient_treatment,
    patient_patient_drug,
    patient_education,
    // patient_report,
    patient_laboratory,
    patient_pharmacy,
    patient_order,
    patient_document_upload,
    patient_chemo_list
  ]
};

const mutations = {
  [types.EXPAND_MENU] (state, menuItem) {
    if (menuItem.index > -1) {
      if (state.items[menuItem.index] && state.items[menuItem.index].meta) {
        state.items[menuItem.index].meta.expanded = menuItem.expanded;
      }
    } else if (menuItem.item && 'expanded' in menuItem.item.meta) {
      menuItem.item.meta.expanded = menuItem.expanded;
    }
  }
};

export default {
  state,
  mutations
};
